<template>
  <div class="about">
    <div class="hero is-info">
      <div class="container">
        <h1 class="is-size-3">{{ $t("credentialManage.title") }}</h1>
      </div>
    </div>
    <hr>
    <div class="container">
      <div class="columns is-centered">
        <div class="column is-7-tablet is-6-desktop is-5-widescreen">
          <div class="is-multiline">
            <a class="is-dark" @click="toList">{{ $t("credentialManage.back") }}</a>
            <h3 class="is-size-4">{{ titleSuffix }}</h3>
            <div class="event-card">
              <div class="card">
                <header class="card-header">
                  <p class="card-header-title">{{ user.displayName }} ({{ user.email }})</p>
                </header>
                <div class="card-content">
                  <div class="content">
                    <label>{{ $t("credentialManage.account") }}</label>
                    <input type="text" v-bind:placeholder="$t('credentialManage.ph-account')" class="input" v-model="account.accountName">
                    <label>{{ $t("credentialManage.description") }}</label>
                    <input type="text" v-bind:placeholder="$t('credentialManage.ph-description')" class="input" v-model="account.description">

                    <!--button class="button is-primary is-small" v-if="needAddCredential" @click="addCredential">Add Credential</button-->
                    <ul v-for="(cred, credIndex) in account.credentialsList">
                      <li>
                        <div class="box">
                          <label>{{ $t("credentialManage.access") }}</label>
                          <input type="text" v-bind:placeholder="$t('credentialManage.ph-access')" class="input" v-model="cred.credentialName">
                          <label>{{ $t("credentialManage.login") }}</label>
                          <input type="text" v-bind:placeholder="$t('credentialManage.ph-login')" class="input" v-model="cred.loginId">
                          <label>{{ $t("credentialManage.password") }}</label>
                          <button class="button is-size-7 has-fixed-size is-warning" @click="toggleShow(cred)">{{ cred.text }}</button>
                          <input :type="cred.show ? 'text' : 'password'" class="input" v-model="cred.showing">
                          <label>{{ $t("credentialManage.p-description") }}</label>
                          <input type="text" v-bind:placeholder="$t('credentialManage.ph-c-description')" class="input" v-model="cred.description">
                          <label>{{ $t("credentialManage.second") }}</label>
                          <input type="text" v-bind:placeholder="$t('credentialManage.ph-second')" class="input" v-model="cred.secondarySecurityMethod">
                          <button class="button is-dark is-small" @click="removeCredential(credIndex)" v-tooltip="$t('credentialManage.delete-c')">
                            <font-awesome-icon icon="minus" />
                          </button>
                        </div>
                      </li>
                      <button class="button is-info is-small" v-if="checkLast(credIndex)" @click="addCredential" v-tooltip="$t('credentialManage.add-c')">
                        <font-awesome-icon icon="plus" />
                      </button>
                    </ul>
                  </div>
                </div>
                <footer class="card-footer">
                  <button class="button is-info card-footer-item" @click="save(account)" v-tooltip="$t('credentialManage.save')">
                    <font-awesome-icon icon="save" />
                  </button>
                  <button class="button is-dark card-footer-item" @click="remove" v-if="showDelete" v-tooltip="$t('credentialManage.delete-a')">
                    <font-awesome-icon icon="trash-alt" />
                  </button>
                </footer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {CredentialServiceClient} from "@/pb/credential_service_grpc_web_pb";
import {AddCredentialRequest, GetCredentialRequest, UpdateCredentialRequest} from "@/pb/credential_service_pb";
import {SecureAccount, Credential} from "@/pb/model_pb";
import router from "@/router";
import security from "@/utility/security";
import {UserClient} from "@/pb/user_service_grpc_web_pb";
import {GetUserRequest} from "@/pb/user_service_pb";
import common from "@/utility/common"

export default {
  name: "CredentialManage",
  data() {
    return {
      account: {},
      user: {},
      titleSuffix: "Edit account",
      needAddCredential: false,
      showDelete: true,
      isAdd: true
    }
  },
  methods: {
    toList: function() {
      router.push("/credential")
    },
    addCredential: function() {
      this.needAddCredential = false
      if (this.account.credentialsList == null) {
        this.account.credentialsList = [{show: false, text: " show "}]
      } else {
        this.account.credentialsList.push({show: false, text: " show "})
      }
    },
    checkLast: function(credIndex) {
      if (credIndex == this.account.credentialsList.length - 1) {
        return true
      }
      return false
    },
    removeCredential: function(credIndex) {
      this.account.credentialsList.splice(credIndex, 1)
      if (this.account.credentialsList.length == 0) {
        this.needAddCredential = true
      }
    },
    remove: function() {
      this.showDelete = false
      this.titleSuffix = "Add New Account"
      this.needAddCredential = true
      let deleteAccount = new SecureAccount()
      deleteAccount.setAccountName(this.account.accountName)
      deleteAccount.setUserEmail(this.account.userEmail)
      deleteAccount.setStatus("delete")
      // need update api update account to mark deletion
      let token = this.$cookies.get('$t')
      let metadata = {'authorization': token}
      this.client = new CredentialServiceClient(common.SECURE_HOST, null, {
        "grpc.max_receive_message_length": common.MESSAGE_LENGTH,
        "grpc.max_send_message_length": common.MESSAGE_LENGTH
      })
      let updateCredentialRequest = new UpdateCredentialRequest()
      updateCredentialRequest.setAccount(deleteAccount)
      this.client.updateCredential(updateCredentialRequest, metadata, (err, response)=>{
        if (err != null) {
          alert("Delete account failed with err " + err)
          return
        }
        if (response != null || response != undefined) {
          alert("Delete account succeeded")
        }
        this.account = {}
      })
    },
    save: function(account) {
      let today = new Date()
      let ts = new proto.google.protobuf.Timestamp()
      ts.setSeconds(Math.floor(today.getTime() / 1000))
      ts.setNanos((today.getTime() % 1000) * 1e6)

      if (account.accountName == "" || account.accountName == undefined) {
        alert("Account Name cannot be empty")
        return
      }
      let newAccount = new SecureAccount()
      newAccount.setAccountName(account.accountName)
      let creds = []
      let passwordError = ""
      for (let i in account.credentialsList) {
        if (account.credentialsList[i].showing == "" || account.credentialsList[i].showing == undefined) {
          passwordError = "password cannot be empty"
          break
        }
        let cred = new Credential()
        cred.setCredentialName(account.credentialsList[i].credentialName)
        let p = account.credentialsList[i].showing
        if (p == "********") {
          p = security.decrypt(account.credentialsList[i].password, this.getKey())
        }
        cred.setPassword(security.encrypt(p, this.getKey()))
        cred.setSecondarySecurityMethod(account.credentialsList[i].secondarySecurityMethod)
        cred.setDescription(account.credentialsList[i].description)
        cred.setUpdatedAt(ts)
        cred.setLoginId(account.credentialsList[i].loginId)
        cred.setShow(account.credentialsList[i].show)
        creds.push(cred)
      }
      if (passwordError != "") {
        alert(passwordError)
        return
      }
      newAccount.setCredentialsList(creds)
      newAccount.setUserEmail(account.userEmail)
      newAccount.setDescription(account.description)
      newAccount.setShareUsersList([])
      newAccount.setStatus("active")
      newAccount.setUpdatedAt(ts)
      let token = this.$cookies.get('$t')
      let metadata = {'authorization': token}
      this.client = new CredentialServiceClient(common.SECURE_HOST, null, {
        "grpc.max_receive_message_length": common.MESSAGE_LENGTH,
        "grpc.max_send_message_length": common.MESSAGE_LENGTH
      })
      if (this.isAdd) {
        let addCredentialRequest = new AddCredentialRequest()
        addCredentialRequest.setAccount(newAccount)
        this.client.addCredential(addCredentialRequest, metadata, (err, response)=>{
          if (!err) {
            this.showDelete = true
          } else {
            alert("Add account failed with error " + err)
            return
          }
        })
      } else {
        let updateCredentialRequest = new UpdateCredentialRequest()
        updateCredentialRequest.setAccount(newAccount)
        this.client.updateCredential(updateCredentialRequest, metadata, (err, response)=>{
          if (err != null) {
            alert("Failed to update credential: " + err)
            return
          }
          if (response != null) {
            alert(response.getMessage())
          }
        })
      }
    },
    toggleShow: function(cred) {
      cred.show = !cred.show
      if (cred.show) {
        cred.text = " hide "
        cred.showing = security.decrypt(cred.password, this.getKey())
      } else {
        cred.text = " show "
        cred.showing = "********"
      }
    },
    getKey: function() {
      return this.$store.getters.getUser.password.substr(0, 32)
    },
    getAccount: function(token, email, accountName) {
      let metadata = {'authorization': token}
      this.client = new CredentialServiceClient(common.SECURE_HOST, null, {
        "grpc.max_receive_message_length": common.MESSAGE_LENGTH,
        "grpc.max_send_message_length": common.MESSAGE_LENGTH
      })
      let getCredentialRequest = new GetCredentialRequest()
      getCredentialRequest.setEmail(email)
      getCredentialRequest.setAccountText(accountName)
      this.client.getCredential(getCredentialRequest, metadata, (err, response)=>{
        if (err != null) {
          alert("Get credentials failed with error: " + err)
          return
        }
        if (response != null) {
          this.account = response.toObject().accountsList[0]
          for (let i in this.account.credentialsList) {
            this.account.credentialsList[i].showing = "********"
            this.account.credentialsList[i].show = false
          }
          this.needAddCredential = false
          this.isAdd = false
        }
      })
    },
    checkAction: function(accountName, token) {
      if (accountName == undefined) {
        this.needAddCredential = true
        this.account = {}
        this.account.userEmail = this.user.email
        let cred = {show: false, text: " show "}
        this.account.credentialsList = [cred]
        this.titleSuffix = "Add new account"
        this.showDelete = false
        this.isAdd = true
      } else {
        this.account = this.$store.getters.getAccount
        if (this.account.accountName == "" || this.account.accountName == undefined || this.account.accountName == null) {
          this.getAccount(token, this.user.email, accountName)
        } else {
          for (let i in this.account.credentialsList) {
            this.account.credentialsList[i].showing = "********"
            this.account.credentialsList[i].show = false
          }
          this.needAddCredential = false
          this.isAdd = false
        }
      }
    }
  },
  mounted() {
    const accountName = this.$route.params.account
    this.$cookies.set("$acct", accountName)
    let uid = this.$cookies.get("$uid")
    let token = this.$cookies.get("$t")

    if (this.$store.getters.isLogIn) {
      this.user = this.$store.getters.getUser
      if (this.user.email == "" || this.user.email == undefined || this.user.email == null) {
        let userEmail = this.CryptoJS.AES.decrypt(
            uid, "jumbosecret").toString(this.CryptoJS.enc.Utf8);

        let metadata = {'authorization': token}
        this.client = new UserClient(common.SECURE_HOST, null, {
          "grpc.max_receive_message_length": common.MESSAGE_LENGTH,
          "grpc.max_send_message_length": common.MESSAGE_LENGTH
        })
        let getUserRequest = new GetUserRequest()
        getUserRequest.setEmail(userEmail)
        this.client.getUser(getUserRequest, metadata, (err, response)=>{
          if (err != null) {
            alert("Get user failed with error: " + err)
            return
          }
          if (response != null) {
            this.user = response.toObject().usersList[0]
            this.$store.commit('setLoggedInUser', this.user)
            this.$root.$emit('updateAuth', true)
            this.checkAction(accountName, token)
          }
        })
      } else {
        this.checkAction(accountName, token)
      }
    }
  }
}
</script>